:root {
    --color-primary-700: red !important;
    --color-primary-500:red !important;
    button {
        --color-primary-500: red !important;
    }
}
.button.primary {
    --color-primary-700: red !important;
}

.apoki-input {
    height: 60px !important;
}